.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

ul {
    list-style: none;
    padding: 0;
}

ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin: 0 2px;
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;
}

ul.pagination li a {
    background-color: transparent;

    color: var(--Grey-600, #adb3ba);
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    text-align: center;
    border: none;
    text-decoration: none;
}

ul.pagination li.active a {
    color: black;
}

a.prev-button,
a.next-button {
    display: inline-block;
    width: 30px;
    height: 30px;
    padding-top: 4px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center;
}
