/* SignUp.tsx */
.sDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.Stitle {
    color: var(--grey-900, #212224);
    font-family: Pretendard;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 39.2px */
    margin-bottom: 34px;
}

/* Sfrom.tsx */
.formDiv {
    width: 465px;
    margin: 100px 0;
    display: flex;
    flex-direction: column;
}
@media (max-height: 832px) {
    .formDiv {
        margin-bottom: 100px;
    }
}

.SformDiv {
    display: flex;
}

.SfromDiv2 {
    margin-right: 25px;
}

.saveBtn {
    border-radius: 6px;
    background: var(--orange-600, #ff7710);
    padding: 10px 22px;
    box-sizing: border-box;
    border: none;
    justify-content: center;
    align-items: center;
    color: var(--white, #fff);
    /* Subtitle/16_Bold */
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    margin-top: 56px;

    align-self: self-end;
}
