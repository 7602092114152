@media screen and (max-width: 767px) {
    .quill > .ql-toolbar.ql-snow {
        box-sizing: border-box;
        padding: 12px 0 12px 21px;
        position: fixed;
        bottom: 0;
        z-index: 99;
        width: 100%;
        border-radius: 0;
        background: #fff;
        border-top: 1px solid var(--Grey-200, #f2f4f6);
        border-bottom: 1px solid var(--Grey-200, #f2f4f6);
    }

    .quill {
        position: relative;
        height: 100%;
    }

    .ql-container.ql-snow {
        border: none;
        height: 100%;
    }

    .ql-editor.ql-blank::before {
        font-family: Pretendard;
        font-style: normal;
        content: attr(data-placeholder);
        left: 20px;
        pointer-events: none;
        position: absolute;
        color: var(--Grey-500, #d1d4d8);
        font-size: 14px;
        font-weight: 500;
        line-height: 160%;
    }

    .ql-editor {
        color: var(--Grey-900, #212224);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        padding: 20px;
        min-height: 0;

        //background: aqua;
    }

    .ql-image {
        width: 100px;
    }

    .ql-snow.ql-toolbar button {
        width: 101px;
        padding: 0;
        color: var(--Grey-900, #212224);
        text-align: center;
        font-family: Pretendard;
        font-size: 0;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        display: flex;
        align-items: center;
        gap: 4px;
    }
}

@media screen and (min-width: 767px) {
    .quill > .ql-toolbar.ql-snow {
        box-sizing: border-box;
        padding: 12px 0 12px 21px;
        border-radius: 6px 6px 0px 0px;
        border: 1px solid var(--Grey-400, #dcdfe3);
        background: var(--Grey-100, #f6f8f9);

        @media screen and (max-width: 767px) {
            position: fixed;
            bottom: 0;
            z-index: 99;
            width: 100%;
            border: none;
            border-radius: 0;
            background: #fff;
            border-top: 1px solid var(--Grey-200, #f2f4f6);
            border-bottom: 1px solid var(--Grey-200, #f2f4f6);
        }
    }

    .quill {
        position: relative;
        height: 100%;
    }

    .ql-container.ql-snow {
        border-radius: 0px 0px 6px 6px;
        border: 1px solid var(--Grey-400, #dcdfe3);
        background: var(--White, #fff);

        @media screen and (max-width: 767px) {
            border: none;
            height: 100%;
        }
    }

    .ql-editor.ql-blank::before {
        font-family: Pretendard;
        font-style: normal;
        content: attr(data-placeholder);
        left: 20px;
        pointer-events: none;
        position: absolute;
        color: var(--Grey-500, #d1d4d8);
        font-size: 16px;
        font-weight: 500;
        line-height: 160%;

        @media screen and (max-width: 767px) {
            font-size: 14px;
        }
    }

    .ql-editor {
        color: var(--Grey-900, #212224);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        padding: 24px;
        min-height: 438px;

        @media screen and (max-width: 767px) {
            font-size: 14px;
            padding: 20px;
            min-height: 0;
        }
    }

    .ql-image {
        width: 100px;
    }

    .ql-snow.ql-toolbar button {
        width: 101px;
        padding: 0;
        color: var(--Grey-900, #212224);
        text-align: center;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        display: flex;
        align-items: center;
        gap: 4px;

        @media screen and (max-width: 767px) {
            font-size: 0;
        }
    }
}
